<template>
  <div class="d-flex align-center flex-shrink-0">
    <div class="text-truncate">{{ $t('ScoreLegend.scale') }}</div>
    <div class="d-flex align-center ml-2">
      <v-chip
        variant="elevated"
        class="mr-1 mb-1"
        size="16"
        style="height: 16px !important; width: 16px !important"
        color="#ffd4ff"
      />
      <div class="text-truncate">{{ $t('ScoreLegend.lowRange') }}</div>
    </div>
    <div class="d-flex align-center ml-4">
      <v-chip
        variant="elevated"
        class="mr-1 mb-1"
        size="16"
        style="height: 16px !important; width: 16px !important"
        color="#ff9de5"
      />
      <div class="text-truncate">{{ $t('ScoreLegend.mediumRange') }}</div>
    </div>
    <div class="d-flex align-center ml-4">
      <v-chip
        variant="elevated"
        class="mr-1 mb-1"
        size="16"
        style="height: 16px !important; width: 16px !important"
        color="#ff619d"
      />
      <div class="text-truncate">{{ $t('ScoreLegend.highRange') }}</div>
    </div>
  </div>
</template>
